<template>
    <div>
        <header-portrate />
        <div class="m-2 pa-2">
            <bread-crumbs :items="items" />
            <tabs-comp :tabs="tabs" />
            <div class="viewCell">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="البحث باسم العميل او رقم السجل أو الرقم الضريبي"
                    hide-details
                ></v-text-field>
                <div class="text-left p-2">
                    <b-button
                        v-b-toggle.sendMessageToCustomer
                        class="btn-sm btn "
                        style="margin-top:11px;width:150px;background:transparent;border:none"
                        id="multiply-button" 
                    >
                    <div style="width:150px;" :class="`multiply-button-content-`+lang.lalgin">إرسال رسالة</div>
                    
                    </b-button>

                    <b-button
                        v-b-toggle.billAddCustomer
                        class="btn-sm btn "
                        style="margin-top:11px;width:150px;background:transparent;border:none"
                        id="multiply-button" 
                        @click="addCustomer({id: 0})"
                    >
                    <div style="width:150px;" :class="`multiply-button-content-`+lang.lalgin">اضافة عميل</div>
                    
                    </b-button>
                    
                </div>
                
            </div>
            <v-row class="d-none d-md-flex">
                <v-col cols="12" style="direction: rtl;">
                    <v-data-table
                        :headers="headers"
                        :items="tableData"
                        :search="search"
                        :items-per-page="15"
                        :page.sync="page"
                        width="100%"
                        id="MainTable"
                        hide-default-footer
                        @page-count="pageCount = $event"
                        fixed-header
                        class="table-sm"
                    >
                        <template v-slot:item="row">
                        <tr>
                            <!-- <td style="border-bottom:1px solid #fff !important;text-align:center;direction:rtl;background:#000">
                                <b-button class="btn btn-sm" style="background:#000;color:#fff;border:none" @click="edit(row.item,3)" v-b-toggle.viewCRMCase>تعديل &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></b-button>
                            </td> -->
                            <td :style="`text-align:center;background:#000;color:#FFF !important;height:30px !important;width:75px !important;cursor:pointer;border-bottom:1px solid #fff !important;`" v-b-toggle.billAddCustomer @click="addCustomer(row.item)">
                                <div class="viewCell">عرض <i class="fas fa-caret-left"></i></div>
                            </td>
                            <td style="text-align:center;">{{ row.item.clientName }}</td>
                            <td style="text-align:center;" nowrap>{{ row.item.clientCRT }}</td>
                            <td style="text-align:center;direction:ltr" nowrap>{{ row.item.clientVAT }}</td>
                            <td style="text-align:center;direction:ltr" nowrap>{{ row.item.clientDomain }}</td>
                            <td style="text-align:center;direction:ltr" nowrap>{{ row.item.LastInvDate.substring(0,10) }}</td>
                            <td style="text-align:center;" nowrap>{{ row.item.lastMessageOpened }}</td>
                            <td style="text-align:center;direction:ltr" nowrap>{{ row.item.expireDate.substring(0,10) }}</td>
                            <td style="text-align:center;" nowrap>{{ row.item.anyDesk }}</td>
                            <td :style="`text-align:center;background:${row.item._statusColor};color:#FFF !important;width:120px`" nowrap>{{ row.item._status }}</td>
                        </tr>
                        </template>
                    </v-data-table>
                    
                    </v-col>
                    <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

                    <v-container>
                        <div class="text-center pt-2">
                        <v-pagination
                            v-model="page"
                            value="10"
                            :length="pageCount"
                        ></v-pagination>
                        </div>
                    </v-container>
                </v-col>
            </v-row>

        </div>
        <sendMessageToCustomer ref="sendMsgTus" />
        <bill-add-customer ref="addCustomer" />
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import BreadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import sendMessageToCustomer from '@/components/sendMessageToCustomers'
import billAddCustomer from '@/components/bill/billAddCustomer.vue'
export default{
    name: 'BillClients',
    components:{HeaderPortrate,BreadCrumbs,TabsComp,sendMessageToCustomer,billAddCustomer},
    data() {
        return {
            search: '',
            page:1,
            pageCount: 0
        }
    },
    computed:{
        ...mapGetters({
            billState: `billStore/billState`
        }),
        lang: function() {
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                text: 'قائمة العملاء',
                disabled: true,
                href: '/',
            }
        },
        tabs: function(){ 
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        tableData: function(){
            return this.billState.clientList;
        },
        headers: function(){
            return [
                {
                    text: 'الاجراء',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'center'
                },
                {
                    text: 'العميل',
                    align: 'center',
                    sortable:false,
                    value: 'clientName',
                },
                {
                    text: 'رقم السجل',
                    align: 'center',
                    sortable:false,
                    value: 'clientCRT'
                },
                {
                    text: 'الرقم الضريبي',
                    align: 'center',
                    sortable:false,
                    value: 'clientVAT'
                },
                {
                    text: 'الدومين',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: ''
                },
                {
                    text: 'آخر فاتورة',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: ''
                },
                {
                    text: 'استقبال الرسائل',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: ''
                },
                {
                    text: 'تاريخ الانتهاء',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: ''
                },
                {
                    text: 'Anydesk',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: ''
                },
                {
                    text: 'الحالة',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: ''
                },
            ]
        }
    },
    methods: {
        ...mapActions({
            getClientList: `billStore/getClientList`
        }),
        sendMsgTus() {
            this.$refs.sendMsgTus.customerList = [];
            this.$refs.sendMsgTus.message = '';
            this.$refs.sendMsgTus.clientStatus = 0;
        },
        addCustomer(item){
            if(item.id == 0){
                this.$refs.addCustomer.blockname = 'اضافة عميل';
                this.$refs.addCustomer.buttonName = 'اضافة';
                this.$refs.addCustomer.itemView = {
                    id: 0,
                    clientType: "ecars",
                    clientName: "",
                    clientCRT: "",
                    clientVAT: "",
                    clientDomain: "",
                    expireDate: "",
                    anyDesk: "",
                    databasename: ""
                };
            }else{
                this.$refs.addCustomer.blockname = 'تعديل عميل';
                this.$refs.addCustomer.buttonName = 'تعديل';
                this.$refs.addCustomer.itemView = item;
            }
        }
    },
    created(){
        this.getClientList();
        setTimeout(() => {console.log("error", this.tableData);},1000)
    }
}
</script>
<style>
.viewCell{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}
</style>